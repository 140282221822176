import React, { useState , useEffect} from "react";
import styles from "./ErrorPopup.module.css";
import SuccessModule from "./SuccessModal";

function SuccessPopup(props) {
  // Event Handlers
  function confirmYesHandler() {
    props.onBookingConfirm(true)
  }  
  function confirmNoHandler(){
    props.onBookingConfirm(false)
  }

  return (
    <div className={!props.bookingSelectTrigger && styles.error}>
      <SuccessModule
        title={props.title}
        message={props.message}
        onYesConfirm={confirmYesHandler}
        onNoConfirm={confirmNoHandler}
      />
    </div>
  );
}
export default SuccessPopup;
