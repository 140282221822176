import SignIn from "../SignIn/SignIn";
import styles from "./SignInPage.module.css";
import { getAuth, setPersistence, signInWithEmailAndPassword, inMemoryPersistence, browserLocalPersistence } from "firebase/auth";

function SignInPage() {
  const auth = getAuth();

  async function AuthenticateUser(UserSignInDetails) {

    await signInWithEmailAndPassword(
      auth,
      UserSignInDetails.email,
      UserSignInDetails.password
    )
    setPersistence(auth, browserLocalPersistence)
  }
  
  return (
    <div className={styles.sign_body}>      
      <SignIn onUserSignIn={AuthenticateUser} />
    </div>
  );
}

export default SignInPage;
