import React, { useState } from "react";
import styles from "./Bookings.module.css";
import BookingsFilter from "./BookingsFilter";
import BookingsList from "./BookingsList";
import BookingsChart from "./BookingsChart";
import Container from "react-bootstrap/Container";

function Bookings(props) {
  // Parent Class passing props to --> BookingsFilter,BookingsChart,BookingsList
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate());
  let defaultFilter = currentDate.toLocaleString("en-US", { weekday: "short" });
  let defaultFilterDay = currentDate.toLocaleString("en-US", { day: "2-digit" });

  const [filterSelected, onSelectedFilter] = useState(defaultFilter);
  const [filterDaySelected, onSelectedFilterDay] = useState(defaultFilterDay);

  function selectedFilterHandler(daySelected) {
    console.log(daySelected);
    onSelectedFilter(daySelected);
  };
  function selectedFilterDayHandler(weekdayNumber){
    onSelectedFilterDay(weekdayNumber);
  };
  const filteredBookings = props.booking_array.filter(
    (bookingItem) => bookingItem.weekDayName === filterSelected && bookingItem.weekDay == filterDaySelected
  ); //This is appling the filter to the bookings by comparing the date selected with the date of each booking

  return (
    <Container className={styles.bookings}>      
      <br />      
      <div className={styles.bookings_chart}>
        <h4>Number of Members Booked</h4>        
      </div>
      <hr className={styles.featurette_divider} />
      <br />
      <BookingsChart bookings={props.booking_array} />
      <br />
      <BookingsFilter
        selected={filterSelected}
        onFilterSelect={selectedFilterHandler}
        FilterDay ={selectedFilterDayHandler}
      />      
      
      <div>
        <br />
        <h3 className={styles.headings}>Available Bookings</h3>
        <p className={styles.paragraph}>
          If you would like to update your Child Minding option on your booking,
          select the child minding option on each booking and simply rebook the
          selected class
        </p>
        <hr className={styles.featurette_divider} />        
      </div>
      <div className={`row row-cols-6 ${styles.booking_titles}`}>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Date</h4>
        </div>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Class Type</h4>
        </div>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Start Time</h4>
        </div>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Duration</h4>
        </div>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Child Minding</h4>
        </div>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Book Class</h4>
        </div>
      </div>
      <hr
        className="featurette_divider booking_divider"
      />
      <br />
      <BookingsList bookingItem={filteredBookings} />
    </Container>
  );
}

export default Bookings;
