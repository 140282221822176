import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import styles from "./Carousel.module.css";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Slide_1 from "../Images/18.jpeg";
import Slide_2 from "../Images/21.jpeg";
import Slide_3 from "../Images/17.jpeg";
import { Link, NavLink } from "react-router-dom";

function Slide() {
  return (
    <div>
      <Container>
        <div className="px-6 py-5 my-5 text-center">
          <h1 className={`display-4 fw-bold ${styles.hero_title}`}>
            Welcome to Emma Jane Fitness
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className={`lead mb-5 ${styles.hero_text}`}>
              We focus on high intensity training. Each session is different
              every day and are modified to help anyone achieve their goals. The
              workouts can be adapted for any age or fitness level.
            </p>
          </div>
          <Carousel className={styles.carousel}>
            <Carousel.Item className={styles.carousel_item}>
              <img className="d-block w-100" src={Slide_1} alt="First slide" />
              <Carousel.Caption
                className={`${styles.carousel_caption1} text-end`}
              >
                <h2>Start Your Fitness Journey</h2>
                <p>
                  Hi I'm Emma, I’m a Mum of 2 wee humans & a qualified PT and
                  Group Fitness Instructor. "Let’s create the best version of
                  you"{" "}
                </p>
                <NavLink>
                  <Link
                    className={`btn btn-lg btn-primary ${styles.button} ${styles.hover}`}
                    to="/SignUp"
                  >
                    Sign Up Today
                  </Link>
                </NavLink>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className={styles.carousel_item}>
              <img className="d-block w-100" src={Slide_2} alt="Second slide" />

              <Carousel.Caption className={styles.carousel_caption2}>
                <h2>View Our Plans</h2>
                <p>Explore our range of plans that best suits your schedule</p>
                <p>
                  <a
                    className={`btn btn-lg btn-primary ${styles.button} ${styles.hover}`}
                  >
                    Coming Soon..
                  </a>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className={styles.carousel_item}>
              <img className="d-block w-100" src={Slide_3} alt="Third slide" />

              <Carousel.Caption
                className={`${styles.carousel_caption3} text-end`}
              >
                <h2>View Our Training Gallery</h2>
                <p>Have a sneak peek to what go's on in our classes.</p>
                <p>
                  <a
                    className={`btn btn-lg btn-primary ${styles.button} ${styles.hover}`}
                  >
                    Coming Soon..
                  </a>
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </Container>
      <div className={styles.b_example_divider}></div>
    </div>
  );
}

export default Slide;
