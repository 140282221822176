import React, { useState, useEffect } from "react";
import styles from "./MyBookingsList.module.css";
import MyBookingItem from "./MyBookingItem";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Accordion from "react-bootstrap/Accordion";
import UserCard from "./UserCard";

let allusersArray = []
async function GetAllUsers(){
  let response = await fetch(
    `https://emmajanefitnessapi.azurewebsites.net/api/Users?userID="users"&allusers=true`,
    {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      headers: {
        "Content-Type": "application/json, charset=utf-8",
      },
    }
  ).then((response) => response.json());
  allusersArray = response
  console.log(allusersArray)  
}
GetAllUsers();

function BookingsList(props) {
  let auth = getAuth();  
  const [admin, EmmaLoggedIn] = useState(false);
  const [LoggedIn, CheckUserLoggedIn] = useState("");
  const [userCardArray, userCardHandler] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  function getUser() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        const adminUser = user.uid;
        console.log(auth.currentUser.displayName);
        CheckUserLoggedIn(adminUser);
        if (
          LoggedIn === "Cp58zcfqSbNRAvlrPTrMOyEWFPf2" ||
          LoggedIn === "4MQZWldm7ddWpgBfx9uXQ0c8ncs1"
        ) {
          EmmaLoggedIn(true);
        }
      }
    });
  }
  function ReadUserData() {    
    const userBookedArray = [];
    props.bookingItem.map((booking) => {
      Object.keys(booking.usersBooked).map(async (user) => {
        setIsLoading(true)
        for (let index = 0; index < allusersArray.length; index++) {
          if (user == allusersArray[index].uid) {
            //Here I build a object for each user that stores all there details
            let userCard = {
              firstName: allusersArray[index].FirstName,
              email: allusersArray[index].Email,
              lastName: allusersArray[index].LastName,
              phoneNumber: allusersArray[index].PhoneNumber,
            };
            //Next I push the card to an array
            userBookedArray.push(userCard);
            //console.log(userBookedArray);
          }          
        }                
      });  
      setIsLoading(false)    
    });
    
    userCardHandler(userBookedArray);
    // Something is going on with this state update only when removed the list is displayed
    console.log(userCardArray); 
    
  }  
  
  useEffect(() => {
    getUser();
    ReadUserData() 
  }, [LoggedIn, props.bookingItem]);
  
  // Parent Class passing props to --> BookingItem
  if (props.bookingItem.length === 0) {
    return (
      <h2 className={styles.bookings_list__fallback}>
        You have no classes booked on this date
      </h2>
    );
  }

  if (admin == true) {
    return (
      <ul className={styles.bookings_list}>
        {/* Remeber that the map function applies what is passed inside to each element of the array*/}
        
        {!isLoading && props.bookingItem.map((booking) => (
          <li>
            <Accordion defaultActiveKey={["0"]} alwaysOpen className={styles.accord}>
              <Accordion.Header >
                <MyBookingItem
                  classType={booking.classType}
                  time={booking.time}
                  duration={booking.duration}
                  date={booking.date}
                  weekDay={booking.weekDay}
                  weekDayName={booking.weekDayName}
                />
              </Accordion.Header>
              <Accordion.Body>
              <div className= {`row ${styles.userTable}`}>
                <h6 className="col">Name</h6>
                <h6 className="col">Last Name</h6>
                <h6 className="col">Email</h6>
                <h6 className="col">Phone Number</h6>
                <h6 className="col">Child Minding</h6>

              </div>
              {userCardArray && userCardArray.map((user) => (
                  <UserCard
                    firstName={user.firstName}
                    lastName={user.lastName}
                    email={user.email}
                    phoneNumber={user.phoneNumber}
                  />
                ))}
                                
              </Accordion.Body>
            </Accordion>
          </li>
        ))}
        {isLoading && <p>Loading</p>}
      </ul>
    );
  }

  return (
    <ul className={styles.bookings_list}>
      {/* Remeber that the map function applies what is passed inside to each element of the array*/}
      {props.bookingItem.map((booking) => (
        <li>
          <MyBookingItem
            classType={booking.classType}
            time={booking.time}
            duration={booking.duration}
            date={booking.date}
            weekDay={booking.weekDay}
            weekDayName={booking.weekDayName}
          />
        </li>
      ))}
    </ul>
  );
}

export default BookingsList;
