import Navbar from "../../UI/NavBar";
import Footer from "../../UI/Footer";
import img from "../../Images/16.jpeg";
import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import styles from "../Pages/TimeTablePage.module.css";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useEffect } from "react";

function TimeTable() {
  const auth = getAuth();
  let userLogin = auth.currentUser;

  let LoggedIn = false;
  let booking_link = "/bookings";

  if (userLogin !== null) {
    LoggedIn = true;
    booking_link = "/bookings";
  } else {
    LoggedIn = false;
    booking_link = "/LogIn";
  }

  return (
    <div className={styles.booking_bg}>
      <Navbar />
      <div className={styles.booking_jumbotron}>
        <Container>
          <div className="row align-items-lg">
            <div className="col-12">
              <div className=" w-100 ">
                <h1 className="p-5">Time Table</h1>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container className={styles.hero}>
        <div className="row px-4 py-5 ">
          <div className={`col-lg-6 ${styles.hero_text}`}>
            <h1 className="display-4 fw-bold">View our Time Table Below</h1>
            <p className="lead mx-0 m-10 ">
              The list below is a standard time table we follow at Emma Jane
              Fitness, but all details on our classes is subject to change. For
              more real time class information, please Sign In if you are
              already a member or Sign Up to View and Book your Classes today.
            </p>
            <div className="text-center">
              <Link
                className={`btn btn-secondary ${styles.button}`}
                to={booking_link}
              >
                View Bookings &raquo;
              </Link>
            </div>
          </div>
          <img src={img} className={`col-lg-6 ${styles.table_img}`} />
        </div>
      </Container>
      <div className={styles.b_example_divider}></div>
      <Container className={styles.booking_bg}>
        <div className={styles.booking_container}>
          <div>
            <div className={`row row-cols-4 ${styles.booking_titles}`}>
              <div className="col-">
                <h4>Class Type</h4>
              </div>
              <div className="col-">
                <h4>Duration</h4>
              </div>
              <div className="col-">
                <h4>Date</h4>
              </div>
              <div className="col-">
                <h4>Instructor</h4>
              </div>
            </div>
            <hr
              className={`${styles.featurette_divider} ${styles.booking_divider}`}
            />
            <div className={`row row-cols-4 ${styles.booking_text}`}>
              <div className="col">HIIT</div>
              <div className="col">30 min</div>
              <div className="col">MON 9:00 am</div>
              <div className="col">Emma</div>
            </div>
            <hr
              className={`${styles.featurette_divider} ${styles.booking_divider}`}
            />
            <div className={`row row-cols-4 ${styles.booking_text}`}>
              <div className="col">HIIT</div>
              <div className="col">30 min</div>
              <div className="col">MON 10:00 am</div>
              <div className="col">Emma</div>
            </div>
            <hr
              className={`${styles.featurette_divider} ${styles.booking_divider}`}
            />
            <div className={`row row-cols-4 ${styles.booking_text}`}>
              <div className="col">HIIT</div>
              <div className="col">30 min</div>
              <div className="col">MON 6:00 pm</div>
              <div className="col">Emma</div>
            </div>
            <hr
              className={`${styles.featurette_divider} ${styles.booking_divider}`}
            />
            <div className={`row row-cols-4 ${styles.booking_text}`}>
              <div className="col">HIIT</div>
              <div className="col">30 min</div>
              <div className="col">WED 9:00 am</div>
              <div className="col">Emma</div>
            </div>
            <hr
              className={`${styles.featurette_divider} ${styles.booking_divider}`}
            />
            <div className={`row row-cols-4 ${styles.booking_text}`}>
              <div className="col">HIIT</div>
              <div className="col">30 min</div>
              <div className="col">WED 10:00 am</div>
              <div className="col">Emma</div>
            </div>
            <hr
              className={`${styles.featurette_divider} ${styles.booking_divider}`}
            />
            <div className={`row row-cols-4 ${styles.booking_text}`}>
              <div className="col">HIIT</div>
              <div className="col">30 min</div>
              <div className="col">WED 5:30 pm</div>
              <div className="col">Emma</div>
            </div>
            <hr
              className={`${styles.featurette_divider} ${styles.booking_divider}`}
            />
            <div className={`row row-cols-4 ${styles.booking_text}`}>
              <div className="col">HIIT</div>
              <div className="col">30 min</div>
              <div className="col">THUR 6:00 pm</div>
              <div className="col">Emma</div>
            </div>
            <hr
              className={`${styles.featurette_divider} ${styles.booking_divider}`}
            />
            <div className={`row row-cols-4 ${styles.booking_text}`}>
              <div className="col">HIIT</div>
              <div className="col">30 min</div>
              <div className="col">FRI 9:15 am</div>
              <div className="col">Emma</div>
            </div>
            <hr
              className={`${styles.featurette_divider} ${styles.booking_divider}`}
            />
            <div className={`row row-cols-4 ${styles.booking_text}`}>
              <div className="col">HIIT</div>
              <div className="col">30 min</div>
              <div className="col">SAT 7:15 am</div>
              <div className="col">Emma</div>
            </div>
            <hr
              className={`${styles.featurette_divider} ${styles.booking_divider}`}
            />
            <div className={`row row-cols-4 ${styles.booking_text}`}>
              <div className="col">HIIT</div>
              <div className="col">30 min</div>
              <div className="col">SAT 8:15 am</div>
              <div className="col">Emma</div>
            </div>
            <hr
              className={`${styles.featurette_divider} ${styles.booking_divider}`}
            />
            <div className={`container ${styles.empty_container}`}></div>
          </div>
        </div>
      </Container>

      <Footer />
    </div>
  );
}

export default TimeTable;
