import React from "react";
import ChartBar from "../Chart/ChartBar";

function BookingsChart(props) {

  // let currentDate = new Date();
  // const chartDatePoints = [
  //   {
  //     label: currentDate.toLocaleString("en-US", { weekday: "short" }),
  //     value: 0,
  //   },
  // ];

  // for (let day = 1; day <= 7; day++) {
  //   let today = new Date();
  //   today.setDate(today.getDate() + day);
  //   let newDate = {
  //     label: today.toLocaleString("en-US", { weekday: "short" }),
  //     value: 0,
  //   };
  //   chartDatePoints.push(newDate);
  // }

  // for (const booking of props.bookings) {
  //   // Use booking of props.bookings instead of booking in props.bookings because props.bookings is an Array and not an Object
  //   const bookingMonth = booking.date.getMonth(); //starting at 0 => January => 0
  //   chartDatePoints[bookingMonth].value += booking.time; //Here we can take advantage of the getMonth() retuning 0 - 11 , this will match the index range of the array chartDatePoints[bookingMonth]
  // }

  return <ChartBar bookingDates = {props.bookings}/>;
}

export default BookingsChart; 
