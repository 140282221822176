import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import styles from "./Featurette.module.css";
import Container from "react-bootstrap/Container";
import Feat_1 from "../Images/4.jpeg";
import Feat_2 from "../Images/20.jpeg";
import Feat_3 from "../Images/6.jpeg";

function Featurette() {
  return (
    <Container>
      <hr className={styles.featurette_divider} />

      <div className="row featurette">
        <div className={`${styles.featurette_container} col-md-6`}>
          <h2 className={`${styles.featurette_heading} fw-normal lh-1`}>
            Train with a friendly community.{" "}
            <span className="text-muted">
              We push each other to bring out that inner beast!
            </span>
          </h2>
          <p className={`lead ${styles.text}`}>
            We are a community of people passionate about fitness. We know each
            other’s names, and we notice and miss you when you are not there.
          </p>
        </div>
        <div className="col-md-6 ">
          <img src={Feat_1} className={styles.img} />
        </div>
      </div>

      <hr className={styles.featurette_divider} />

      <div className="row featurette">
        <div
          className={`${styles.featurette_container} col-md-6 order-md-2 midFeat`}
        >
          <h2 className={`${styles.featurette_heading} fw-normal lh-1`}>
            Oh yeah, HIIT is that good!{" "}
            <span className="text-muted">See for yourself.</span>
          </h2>
          <p className={`lead ${styles.text}`}>
            In HIIT training workouts, the intensity levels are adjusted to
            challenge every individual from the beginner to the elite.
          </p>
        </div>
        <div className="col-md-6 order-md-1">
          <img src={Feat_2} className={styles.img} />
        </div>
      </div>

      <hr className={styles.featurette_divider} />

      <div className="row featurette">
        <div className={`${styles.featurette_container} col-md-6`}>
          <h2 className={`${styles.featurette_heading} fw-normal lh-1`}>
            Our instructor's will show you the ropes{" "}
            <span className="text-muted">
              and guide you through the workout
            </span>
          </h2>
          <p className={`lead ${styles.text}`}>
            No need to spend time wondering if you are doing the exercises
            correctly. Our instructor will demonstrate the most efficient way to
            perfect your form. Get in position, follow along 
            and enjoy sculpting your new body!
          </p>
        </div>
        <div className="col-md-6">
          <img src={Feat_3} className={styles.img} />
        </div>
      </div>
      <hr className={styles.featurette_divider} />
    </Container>
  );
}

export default Featurette;
