import React, { useState , useRef} from "react";
import Navbar from "../../UI/NavBar";
import Footer from "../../UI/Footer";
import styles from "./UserProfilePage.module.css";
import Container from "react-bootstrap/Container";
import { getAuth ,onAuthStateChanged } from "firebase/auth";

function UserProfilePage() {
  const auth = getAuth();   
  //User Data
  const email = useRef("");
  const FirstName = useRef("");
  const LastName = useRef("");
  const DisplayName = useRef("");
  const PhoneNumber = useRef("");
  const [LoggedIn, CheckUserLoggedIn] = useState("");
  const [greeting, UserGreeting] = useState("");
  
  //User Data Handlers
  async function SignUpSubmitHandler(event) {
    event.preventDefault(); // Remember!! By default a button placed inside a form will act as a submit button and reload the browser
    // By page will reload because of a request being sent to the sever to submit the form
    // But we don't want that, we want to control the submit with JavaScript
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.from(forms).forEach((form) => {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
    });
    UpdateUserDataFromServer();
    window.location.reload(true); // Refresh the browser
  }
  function getUser() {
    
    onAuthStateChanged(auth, (user) => {
      if (user ) {
        // User is signed in, see docs for a list of available properties
        const uid = user.uid;
        CheckUserLoggedIn(uid);        
      }
    });
  }
  async function ReadUserData() {
    getUser()

    let response = await fetch(
      `https://emmajanefitnessapi.azurewebsites.net/api/Users?userID=${LoggedIn}&allusers=false`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json, charset=utf-8",
        },
      }
    ).then((response) => response.json());
    console.log(response);
    email.current.value = response.Email
    FirstName.current.value = response.FirstName
    LastName.current.value = response.LastName
    DisplayName.current.value = response.DisplayName  
    PhoneNumber.current.value = response.PhoneNumber
    UserGreeting(DisplayName.current.value)
  }
  async function UpdateUserDataFromServer() {
    const auth = getAuth();
    let userJson = JSON.stringify({
      firstName: FirstName.current.value,
      lastName: LastName.current.value,
      email: email.current.value,
      phoneNumber: PhoneNumber.current.value,
      displayName: DisplayName.current.value,
      userID: auth.currentUser.uid,
    });

    //Update/PUT method implementation:
    await fetch(
      `https://emmajanefitnessapi.azurewebsites.net/api/Users?jsonString=${userJson}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json, charset=utf-8",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "strict-origin-when-cross-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: userJson, // body data type must match "Content-Type" header
      }
    );
  }

  ReadUserData();

  return (
    <div>
      <Navbar />
      <Container className={`${styles.body}`}>
        <div className={`text-center `}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            fill="currentColor"
            className={`d-block mx-auto mb-4 bi bi-person-vcard ${styles.logo}`}
            viewBox="0 0 16 16"
          >
            <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5ZM9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8Zm1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Z" />
            <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2ZM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96c.026-.163.04-.33.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1.006 1.006 0 0 1 1 12V4Z" />
          </svg>
          <h2>Hi, {greeting}</h2>
          <p className="lead">
            Update your details below if any of your information is incorrect or
            outdated
          </p>
        </div>
        <div className={`row g-5 ${styles.fields}`}>
          <div className={`col-xs-6 col-md-6 col-lg-6`}>
            <h4 className="mb-3">Your Profile Details</h4>
            <hr className="my-4" />

            <form
              className={`needs-validation text-center ${styles.contain}`}
              novalidate="novalidate"
              onSubmit={SignUpSubmitHandler}
            >
              <div className="row g-3 ">
                <div className="col-xs-6 col-sm-6 col-lg-6">
                  <label for="firstName" className="form-label">
                    First name
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.form_signUp}`}
                    id="firstName"
                    placeholder="Loading First Name..."
                    required
                    ref={FirstName}
                  />
                  <div className="invalid-feedback">
                    Valid first name is required.
                  </div>
                </div>

                <div className="col-xs-6 col-sm-6 col-lg-6">
                  <label for="lastName" className="form-label">
                    Last name
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.form_signUp}`}
                    id="lastName"
                    placeholder="Loading Last Name..."
                    required
                    ref={LastName}
                  />
                  <div className="invalid-feedback">
                    Valid last name is required.
                  </div>
                </div>
              </div>

              <div className="row-12">
                <label for="username" className="form-label">
                  Display Name
                </label>
                <div className="needs-validation">
                  <input
                    type="text"
                    className={`form-control ${styles.form_signUp} `}
                    id="username"
                    placeholder="Loading Display Name..."
                    required
                    ref={DisplayName}
                  />
                  <div className="invalid-feedback">
                    Your username is required.
                  </div>
                </div>
              </div>

              <div className="row-12">
                <label for="email" className="form-label">
                  Email
                </label>
                <div className="needs-validation">
                  <input
                    type="email"
                    className={`form-control ${styles.form_signUp}`}
                    id="email"
                    name="email"
                    placeholder="Loading Email..."
                    required
                    ref={email}
                  />
                  <div className="invalid-feedback">
                    "Please enter a valid email address."
                  </div>
                </div>
              </div>

              <div className="row-12">
                <label for="address" className="form-label">
                  Phone Number
                </label>
                <input
                  type="tel"
                  className={`form-control ${styles.form_signUp}`}
                  id="phone"
                  name="phone"
                  pattern="[0-9]{10}"
                  placeholder="Loading Phone Number..."
                  required
                  ref={PhoneNumber}
                />
                <div className="invalid-feedback">
                  Please enter a valid 10 digit phone number.
                </div>
              </div>

              <hr className="my-4" />

              <div className={`row-12 ${styles.Sbutton}`}>
                <button
                  className={`btn btn-primary btn-lg ${styles.signButton}`}
                  type="submit"
                >
                  Update Info
                </button>
              </div>
            </form>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default UserProfilePage;
