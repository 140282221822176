import styles from "./Icons.module.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { getAuth } from "firebase/auth";

function Icons() {

  const auth = getAuth();
  let userLogin = auth.currentUser;

  let LoggedIn = false;
  let booking_link = "/bookings";

  if (userLogin !== null) {
    LoggedIn = true;
    booking_link = "/bookings";
  } else {
    LoggedIn = false;
    booking_link = "/LogIn";
  }

  return (
    <Container>
      <div className={` ${styles.marketing}`}>
        <div className="row">
          <div className={`col-lg-4 col-xs-12 ${styles.icon_container}`}>
            <svg
              className={styles.icon}
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 16 16"
              preserveAspectRatio="xMidYMid slice"
            >
              <path d="M1.475 9C2.702 10.84 4.779 12.871 8 15c3.221-2.129 5.298-4.16 6.525-6H12a.5.5 0 0 1-.464-.314l-1.457-3.642-1.598 5.593a.5.5 0 0 1-.945.049L5.889 6.568l-1.473 2.21A.5.5 0 0 1 4 9H1.475Z" />
              <path d="M.88 8C-2.427 1.68 4.41-2 7.823 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C11.59-2 18.426 1.68 15.12 8h-2.783l-1.874-4.686a.5.5 0 0 0-.945.049L7.921 8.956 6.464 5.314a.5.5 0 0 0-.88-.091L3.732 8H.88Z" />
            </svg>

            <h2 className={`fw-normal ${styles.heading}`}>Classes</h2>
            <p>
              See what HIIT training is all about. And find out more on our
              classes that we offer.
            </p>
            <p>
              <a className={`btn btn-secondary ${styles.button}`} href="#">
                Coming Soon..
              </a>
            </p>
          </div>
          <div className={`col-lg-4 ${styles.icon_container}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="140"
              height="140"
              fill="currentColor"
              className={styles.icon}
              viewBox="0 0 16 16"
              preserveAspectRatio="xMidYMid slice "
            >
              <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z" />
            </svg>

            <h2 className="fw-normal J">Challenges</h2>
            <p>
              See the amazing results of our clients who took out 8-week
              challenge. And explore our upcoming challenges.
            </p>
            <p>
              <a className={`btn btn-secondary ${styles.button}`} href="#">
                Coming Soon..
              </a>
            </p>
          </div>
          <div className={`col-lg-4 ${styles.icon_container}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="140"
              height="140"
              fill="currentColor"
              className={styles.icon}
              viewBox="0 0 16 16"
              preserveAspectRatio="xMidYMid slice"
            >
              <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
            </svg>

            <h2 className="fw-normal J">Bookings</h2>
            <p>Ready for some life changing team training, book today.</p>
            <p>
              <Link
                className={`btn btn-secondary ${styles.button}`}
                to={booking_link}
              >
                View details &raquo;
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Icons;
