import React, { useState, useEffect } from "react";
import styles from "./MyBookings.module.css";
import BookingsFilter from "../BookingsFilter";
import MyBookingsList from "./MyBookingsList";
import Container from "react-bootstrap/Container";
import { getAuth, onAuthStateChanged } from "firebase/auth";

function Bookings(props) {
  let auth = getAuth();
  // Parent Class passing props to --> BookingsFilter,BookingsChart,BookingsList
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate());
  let defaultFilter = currentDate.toLocaleString("en-US", { weekday: "short" });
  let defaultFilterDay = currentDate.toLocaleString("en-US", {
    day: "2-digit",
  });
  const [admin, EmmaLoggedIn] = useState(false);
  const [LoggedIn, CheckUserLoggedIn] = useState("");
  const [filterSelected, onSelectedFilter] = useState(defaultFilter);
  const [filterDaySelected, onSelectedFilterDay] = useState(defaultFilterDay);
  function getUser() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        const adminUser = user.uid;
        console.log(auth.currentUser.displayName);
        CheckUserLoggedIn(adminUser);
        if (
          LoggedIn === "Cp58zcfqSbNRAvlrPTrMOyEWFPf2" ||
          LoggedIn === "4MQZWldm7ddWpgBfx9uXQ0c8ncs1"
        ) {
          EmmaLoggedIn(true);
        }
      }
    });
  }
  function selectedFilterHandler(daySelected) {
    console.log(daySelected);
    onSelectedFilter(daySelected);
  }
  function selectedFilterDayHandler(weekdayNumber) {
    onSelectedFilterDay(weekdayNumber);
  }
  const filteredBookings = props.booking_array.filter(
    (bookingItem) =>
      bookingItem.weekDayName === filterSelected &&
      bookingItem.weekDay == filterDaySelected
  ); //This is appling the filter to the bookings by comparing the date selected with the date of each booking
  
  useEffect(()=>{
    getUser()
  },[LoggedIn])

  if (admin == true) {
    return (
      <Container className={styles.bookings}>
        <br />
        
        <BookingsFilter
          selected={filterSelected}
          onFilterSelect={selectedFilterHandler}
          FilterDay={selectedFilterDayHandler}
        />
  
        <div>
          <br />
          <h3 className={styles.headings}>Your Booked Classes</h3>
          <p className={styles.paragraph}>          
            
          </p>
          <hr className={styles.featurette_divider} />
        </div>
        <div className={`row row-cols-6 ${styles.booking_titles}`}>
          <div className="col-lg col-xs col-md col-sm">
            <h4>Date</h4>
          </div>
          <div className="col-lg col-xs col-md col-sm">
            <h4>Class Type</h4>
          </div>
          <div className="col-lg col-xs col-md col-sm">
            <h4>Start Time</h4>
          </div>
          <div className="col-lg col-xs col-md col-sm">
            <h4>Duration</h4>
          </div>
          
          
        </div>
        <hr className="featurette_divider booking_divider" />
        <br />
        <MyBookingsList bookingItem={filteredBookings} />
      </Container>
    );
  }

  return (
    <Container className={styles.bookings}>
      <br />
      
      <BookingsFilter
        selected={filterSelected}
        onFilterSelect={selectedFilterHandler}
        FilterDay={selectedFilterDayHandler}
      />

      <div>
        <br />
        <h3 className={styles.headings}>Your Booked Classes</h3>
        <p className={styles.paragraph}>          
          If you would like to Cancel your booking,
          click on the cancel booking icon from the your list of booked classes below.
        </p>
        <hr className={styles.featurette_divider} />
      </div>
      <div className={`row row-cols-6 ${styles.booking_titles}`}>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Date</h4>
        </div>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Class Type</h4>
        </div>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Start Time</h4>
        </div>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Duration</h4>
        </div>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Child Minding</h4>
        </div>
        <div className="col-lg col-xs col-md col-sm">
          <h4>Cancel Booking</h4>
        </div>
      </div>
      <hr className="featurette_divider booking_divider" />
      <br />
      <MyBookingsList bookingItem={filteredBookings} />
    </Container>
  );
}

export default Bookings;

