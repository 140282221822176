import styles from "./SuccessModule.module.css";

function SuccessModule(props) {
  function yesButton() {
    props.onYesConfirm();
  };
  function noButton() {
    props.onNoConfirm();
  };

  return (
    <div>
      <div className={styles.backdrop} />
      <div className={styles.modal}>
        <header className={styles.header}>
          <h2>{props.title}</h2>
        </header>
        <div className={styles.content}>
          <p>{props.message}</p>
        </div>
        <footer className={styles.newUser__action}>
          <button
            className={styles.confirmErrorButton}
            type="button"
            onClick={noButton}>
            No
          </button>
          <button
            className={styles.confirmErrorButton}
            type="button"
            onClick={yesButton}>
            Yes
          </button>
        </footer>
      </div>
    </div>
  );
}

export default SuccessModule;
