import React, { useState } from "react";
import styles from "./BookingsFilter.module.css";

const BookingsFilter = (props) => {
  // Child Class getting props from  --> Bookings
  let currentDate = new Date();
  let dateObjects = [
    {
      month: currentDate.toLocaleString("en-US", { month: "short" }),
      day: currentDate.toLocaleString("en-US", { day: "2-digit" }),
      weekday: currentDate.toLocaleString("en-US", { weekday: "short" }),
      key: 0,
    },
  ];

  for (let day = 1; day <= 7; day++) {
    let today = new Date();
    today.setDate(today.getDate() + day);
    let newDate = {
      month: today.toLocaleString("en-US", { month: "short" }),
      day: today.toLocaleString("en-US", { day: "2-digit" }),
      weekday: today.toLocaleString("en-US", { weekday: "short" }),
      key: { day },
    };
    dateObjects.push(newDate);
  }

  const [selected0Day, Day0Select] = useState(false);
  const [selected1Day, Day1Select] = useState(false);
  const [selected2Day, Day2Select] = useState(false);
  const [selected3Day, Day3Select] = useState(false);
  const [selected4Day, Day4Select] = useState(false);
  const [selected5Day, Day5Select] = useState(false);
  const [selected6Day, Day6Select] = useState(false);
  const [selected7Day, Day7Select] = useState(false);

  function selectFilterHandler(event) {
    event.preventDefault();
    let filtedDay = event.target.innerText.split("\n")[2];
    let filtedDayNumber = event.target.innerText.split("\n")[1];
    props.onFilterSelect(filtedDay);
    props.FilterDay(filtedDayNumber);
  }

  function Button0(event) {
    Day0Select(true);
    Day1Select(false);
    Day2Select(false);
    Day3Select(false);
    Day4Select(false);
    Day5Select(false);
    Day6Select(false);
    Day7Select(false);
    selectFilterHandler(event);
  }
  function Button1(event) {
    Day0Select(false);
    Day1Select(true);
    Day2Select(false);
    Day3Select(false);
    Day4Select(false);
    Day5Select(false);
    Day6Select(false);
    Day7Select(false);
    selectFilterHandler(event);
  }
  function Button2(event) {
    Day0Select(false);
    Day1Select(false);
    Day2Select(true);
    Day3Select(false);
    Day4Select(false);
    Day5Select(false);
    Day6Select(false);
    Day7Select(false);
    selectFilterHandler(event);
  }
  function Button3(event) {
    Day0Select(false);
    Day1Select(false);
    Day2Select(false);
    Day3Select(true);
    Day4Select(false);
    Day5Select(false);
    Day6Select(false);
    Day7Select(false);
    selectFilterHandler(event);
  }
  function Button4(event) {
    Day0Select(false);
    Day1Select(false);
    Day2Select(false);
    Day3Select(false);
    Day4Select(true);
    Day5Select(false);
    Day6Select(false);
    Day7Select(false);
    selectFilterHandler(event);
  }
  function Button5(event) {
    Day0Select(false);
    Day1Select(false);
    Day2Select(false);
    Day3Select(false);
    Day4Select(false);
    Day5Select(true);
    Day6Select(false);
    Day7Select(false);
    selectFilterHandler(event);
  }
  function Button6(event) {
    Day0Select(false);
    Day1Select(false);
    Day2Select(false);
    Day3Select(false);
    Day4Select(false);
    Day5Select(false);
    Day6Select(true);
    Day7Select(false);
    selectFilterHandler(event);
  }
  function Button7(event) {
    Day0Select(false);
    Day1Select(false);
    Day2Select(false);
    Day3Select(false);
    Day4Select(false);
    Day5Select(false);
    Day6Select(false);
    Day7Select(true);
    selectFilterHandler(event);
  }

  return (
    <div className={styles.bookings_filter}>
      <br />
      <div>
        <h4>Select A Date To View Availability</h4>
      </div>
      <hr className={styles.featurette_divider} />
      <div className={`row-12 ${styles.booking_days}`}>
        <br />
        <button
          className={`col-lg-2 col-sm-2 col-5 ${styles.filterDay} ${
            selected0Day && styles.filterDay_active
          }`}
          onClick={Button0}
          type="button"
        >
          {dateObjects[0].month}
          <br />
          {dateObjects[0].day}
          <br />
          {dateObjects[0].weekday}
        </button>
        <button
          className={`col-lg-2 col-sm-2 col-5 ${styles.filterDay} ${
            selected1Day && styles.filterDay_active
          }`}
          onClick={Button1}
          type="button"
        >
          {dateObjects[1].month}
          <br />
          {dateObjects[1].day}
          <br />
          {dateObjects[1].weekday}
        </button>
        <button
          className={`col-lg-2 col-sm-2 col-5 ${styles.filterDay} ${
            selected2Day && styles.filterDay_active
          }`}
          onClick={Button2}
          type="button"
        >
          {dateObjects[2].month}
          <br />
          {dateObjects[2].day}
          <br />
          {dateObjects[2].weekday}
        </button>
        <button
          className={`col-lg-2 col-sm-2 col-5 ${styles.filterDay} ${
            selected3Day && styles.filterDay_active
          }`}
          onClick={Button3}
          type="button"
        >
          {dateObjects[3].month}
          <br />
          {dateObjects[3].day}
          <br />
          {dateObjects[3].weekday}
        </button>

        <br />
      </div>
      <div className={`row-12 ${styles.booking_days}`}>
        <button
          className={`col-lg-2 col-sm-2 col-5 ${styles.filterDay} ${
            selected4Day && styles.filterDay_active
          }`}
          onClick={Button4}
          type="button"
        >
          {dateObjects[4].month}
          <br />
          {dateObjects[4].day}
          <br />
          {dateObjects[4].weekday}
        </button>
        <button
          className={`col-lg-2 col-sm-2 col-5 ${styles.filterDay} ${
            selected5Day && styles.filterDay_active
          }`}
          onClick={Button5}
          type="button"
        >
          {dateObjects[5].month}
          <br />
          {dateObjects[5].day}
          <br />
          {dateObjects[5].weekday}
        </button>
        <button
          className={`col-lg-2 col-sm-2 col-5 ${styles.filterDay} ${
            selected6Day && styles.filterDay_active
          }`}
          onClick={Button6}
          type="button"
        >
          {dateObjects[6].month}
          <br />
          {dateObjects[6].day}
          <br />
          {dateObjects[6].weekday}
        </button>
        <button
          className={`col-lg-2 col-sm-2 col-5 ${styles.filterDay} ${
            selected7Day && styles.filterDay_active
          }`}
          onClick={Button7}
          type="button"
        >
          {dateObjects[7].month}
          <br />
          {dateObjects[7].day}
          <br />
          {dateObjects[7].weekday}
        </button>
      </div>
    </div>
  );
};

export default BookingsFilter;
