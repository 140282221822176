import styles from "./NavBar.module.css";
import EJF_Logo from "../Images/EJF_Logo.jpeg";
import { Link, NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import "../../node_modules/bootstrap/dist/css/bootstrap.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

function NavBar() {
  const [LoggedIn, CheckUserLoggedIn] = useState(false);
  let signUp_Link = "/SignUp";
  let logIn_Link = "/LogIn";
  const auth = getAuth();
  // Programmically navigate page
  const navigate = useNavigate();

  function getUser() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        const uid = user.uid;
        CheckUserLoggedIn(true);
        logIn_Link = "";
        signUp_Link = "";
      } else {
        logIn_Link = "/LogIn";
        signUp_Link = "/SignUp";
      }
    });
    console.log(auth.currentUser);
  }
  function NavigateHome() {
    navigate("/");
  }
  function NavigateProfilePage() {
    navigate("/profilePage");
  }
  function NavigatemyBookingsPage() {
    navigate("/myBookingsPage");
  }
  function SignOut() {
    auth.signOut();
    NavigateHome();
    window.location.reload(true); // Refresh the browser
  }
  useEffect(() => {
    getUser();
  }, []);

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/" className={styles.Nav_brand}>
          <img
            alt=""
            src={EJF_Logo}
            className={`d-inline-block align-center ${styles.Nav_logo}`}
          />{" "}
          Emma Jane Fitness
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={`${styles.Nav_button2}`}>
          
          <Nav.Link >
            <Link className="nav-link active" aria-current="page" to={"/"}>
              <h4 className={styles.Navbar_titles}>Home</h4>
            </Link>
          </Nav.Link>
          <Nav.Link >
            <Link className="nav-link" to={"/timeTable"}>
              <h4 className={styles.Navbar_titlesL}>Time Table</h4>
            </Link>
          </Nav.Link>
          {LoggedIn && 
            <Nav.Link >
              <Link className="nav-link" to={"/bookings"}>
                <h4 className={styles.Navbar_titlesM}>Bookings</h4>
              </Link>
            </Nav.Link>
          }

          {!LoggedIn && 
            <Nav.Link className={`${styles.Nav_button1}`}>
              <Link to={logIn_Link} style={{ textDecoration: "none" }}>
                <button
                  type="button"
                  className={`btn ${styles.log_in}`}
                >
                  Log In
                </button>
              </Link>
            </Nav.Link>
          }

          {!LoggedIn && 
            <Nav.Link >
              <Link to={signUp_Link} style={{ textDecoration: "none" }}>
                <button
                  type="button"
                  className={`btn ${styles.sign_up}`}
                >
                  Sign Up
                </button>
              </Link>
            </Nav.Link>
          }

          {LoggedIn && 
            <Dropdown className={`${styles.profile}`}>
              <Dropdown.Toggle data-bs-display="static" className={`${styles.profile}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  className="bi bi-person-square"
                  viewBox="0 0 16 16"
                  >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12z" />
                </svg>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-menu-start ">
                <Dropdown.Item href="#" onClick={NavigateProfilePage}>
                  Profile
                </Dropdown.Item>
                <Dropdown.Item href="#" onClick={NavigatemyBookingsPage}>My Bookings</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#" onClick={SignOut}>
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
