import styles from "./BookingsList.module.css";
import BookingItem from "./BookingItem";

function BookingsList(props) {
  // Parent Class passing props to --> BookingItem
  if (props.bookingItem.length === 0) {
    return (
      <h2 className={styles.bookings_list__fallback}>
        No classes available for booking today
      </h2>
    );
  }

  return (
    <ul className={styles.bookings_list}>
      {/* Remeber that the map function applies what is passed inside to each element of the array*/}
      {props.bookingItem.map((booking) => (
        <li>
          <BookingItem            
            classType={booking.classType}
            time={booking.time}
            duration={booking.duration}
            date={booking.date}
            weekDay={booking.weekDay}
            weekDayName={booking.weekDayName}
          />
        </li>
      ))}
    </ul>
  );
}

export default BookingsList;
