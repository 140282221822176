import React, { useState } from "react";
import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import EJF_Logo from "../../Images/EJF_Logo.jpeg";
import styles from "../SignIn/SignIn.module.css";
import { useNavigate } from "react-router-dom";

function SignIn(props) {
  const [email, EmailSubmit] = useState("");
  const [password, PasswordSubmit] = useState("");
  const [FirebaseErrorMsg, ErrorMessageResponse] = useState("");
  let FirebaseError = "Please enter a valid email address.";

  function EmailSubmitHandler(event) {
    EmailSubmit(event.target.value);
  }
  function PasswordSubmitHandler(event) {
    PasswordSubmit(event.target.value);
  }
  // Programmically navigate page
  const navigate = useNavigate();

  function handleClick() {
    navigate("/");
  }

  async function SignInSubmitHandler(event) {
    event.preventDefault(); // Remember!! By default a button placed inside a form will act as a submit button and reload the browser
    // By page will reload because of a request being sent to the sever to submit the form
    // But we don't want that, we want to control the submit with JavaScript

    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.from(forms).forEach((form) => {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
    });

    let SignInDetails = { email, password };

    if (email != "" && password != "") {
      await props
        .onUserSignIn(SignInDetails)
        .then((response) => {
          FirebaseError = response;
          console.log(FirebaseError);
          ErrorMessageResponse(FirebaseError);

          if (FirebaseError != "") {
            EmailSubmit("");
            PasswordSubmit("");
            ErrorMessageResponse("Log In Sucessfull");
            handleClick();
            // Add a popup saying sign up successful!
            console.log("Log In Sucessfull");
          } else {
            console.log(FirebaseError);
            ErrorMessageResponse(FirebaseError);
            EmailSubmit("");
            PasswordSubmit("");
            throw new Error(FirebaseError);
          }
        })
        .catch((error) => {
          FirebaseError = error.message;
          ErrorMessageResponse(FirebaseError);
          console.log(FirebaseError);
          EmailSubmit("");
          PasswordSubmit("");
          throw new Error(FirebaseError);
        });
    } else {
      ErrorMessageResponse("Please enter a valid email address & password");
    }
    FirebaseError = "";
  }

  return (
    <div className={`${styles.body} h-100`}>
      <form
        className={`needs-validation w-100 m-auto text-center ${styles.form_signin}`}
        onSubmit={SignInSubmitHandler}
        novalidate="novalidate"
      >
        <div className={`${styles.closeLoc} `}>
          <button
            type="button"
            className={`btn-close ${styles.close} `}
            aria-label="Close"
            onClick={handleClick}
            
          ></button>
        </div>

        <img
          className={`mb-4 ${styles.logo}`}
          src={EJF_Logo}
          alt=""
          width="100"
          height="100"
        />
        <h1 className="h3 mb-4 fw-normal">Log in to your account</h1>

        <div className={`form-floating ${styles.fields}`}>
          <input
            type="email"
            className="form-control"
            id="floatingInput"
            placeholder="name@example.com"
            onChange={EmailSubmitHandler}
            value={email}
            required
          />
          <label for="floatingInput">Email address</label>
        </div>
        <div className={`form-floating ${styles.fields}`}>
          <input
            type="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Password"
            onChange={PasswordSubmitHandler}
            value={password}
            required
            minlength="6"
          />
          <div className="invalid-feedback">{FirebaseErrorMsg}</div>
          <label for="floatingPassword">Password</label>
        </div>

        <button
          className={`w-100 btn btn-lg btn-primary ${styles.signButton}`}
          type="submit"
        >
          Log in
        </button>
        <p className="mt-5 mb-3 text-muted">&copy; 2022–2023</p>
      </form>
    </div>
  );
}
export default SignIn;
