import React, { useState, useEffect } from "react";

function UserCard(props) {
  return (
    <>
    <div className="row">
      <h6 className="col">{props.firstName}</h6>
      <h6 className="col">{props.lastName}</h6>
      <h6 className="col">{props.email}</h6>
      <h6 className="col">{props.phoneNumber}</h6>
      <h6 className="col">false</h6>
    </div>
      
    </>
  );
}

export default UserCard;
