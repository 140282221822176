import React from "react";
import styles from "./ChartBar.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import ProgressBar from "react-bootstrap/ProgressBar";

function ChartBar(props) {
  // let barFillHeight = "50%";

  // if(props.maxValue > 0){
  //     barFillHeight = Math.round((props.value / props.maxValue) * 100) +"%";
  // }
  // return(
  //     <div className={styles.chart_bar}>
  //         <div className={styles.chart_bar__inner}>
  //             <div className={styles.chart_bar__fill} style={{width: barFillHeight}}>
  //             </div>
  //         </div>
  //         <div className={styles.chart_bar__label}>{props.label}</div>
  //     </div>
  // )

  return (
    <div className="row">
      <div className={`col-lg-4 col-xs-2 ${styles.progress}`}>
        <h5>Mon</h5>
        <h5>Tue</h5>
        <h5>Wed</h5>
        <h5>Thu</h5>
        <h5>Fri</h5>
        <h5>Sat</h5>
        <h5>Sun</h5>
        <h5>Mon</h5>
      </div>
      <div className={`col-lg-6 col-xs-4 ${styles.progress}`}>        
        <div className={`${styles.progress_bar}`}>
          <ProgressBar variant="info" now={20} animated="true" />
        </div>
        <div className={`${styles.progress_bar}`}>
          <ProgressBar variant="success" now={40} animated="true" />
        </div>
        <div className={`${styles.progress_bar}`}>
          <ProgressBar variant="warning" now={60} animated="true" />
        </div>
        <div className={`${styles.progress_bar}`}>
          <ProgressBar variant="danger" now={80} animated="true" />
        </div>
        <div className={`${styles.progress_bar}`}>
          <ProgressBar variant="info" now={20} animated="true" />
        </div>
        <div className={`${styles.progress_bar}`}>
          <ProgressBar variant="success" now={40} animated="true" />
        </div>
        <div className={`${styles.progress_bar}`}>
          <ProgressBar variant="warning" now={60} animated="true" />
        </div>
        <div className={`${styles.progress_bar}`}>
          <ProgressBar variant="danger" now={80} animated="true" />
        </div>        
      </div>
    </div>
  );
}

export default ChartBar;
