import React, { useState } from "react"; // useState is a React Hook
import styles from "./BookingForm.module.css";

function BookingForm(props) {
  // use Hooks can only be called in React components non normal JS
  // Hooks are used to re-evaluate the data when an onClick/onWhatEver Handler has been triggered to update the value and redraw it on the screen

  // With the useState you create a special kind of variable. useState gets passed the initial value as a argument
  // useState returns 2 things for us: 1. Gives us access to this special variable
  // 2. Returns a function that we can call to assign a new value to the variable
  // Summary: useState returns an Array = [value itself, updating function]

  // You can use destucturing to store both returned elements into seperate variables/constants

  const [enteredTitle, setEnteredTitle] = useState("");
  const [enteredTime, setEnteredTime] = useState("");
  const [enteredDate, setEnteredDate] = useState("");
  const [enteredDuration, setEnteredDuration] = useState("");

  const [create, createButtonState] = useState(false);
  const [cancel, cancelButtonState] = useState(false);

  function titleChangeHandler(event) {
    setEnteredTitle(event.target.value);
    // Every time the onChange Event is triggered the current entered value is stored in enteredTitle,
    //through the state fuction
  };
  function timeChangeHandler(event) {
    setEnteredTime(event.target.value);
    //value returned by event.target.value will always be a string
  };
  function dateChangeHandler(event) {
    setEnteredDate(event.target.value);
  };
  function durationChangeHandler(event) {
    setEnteredDuration(event.target.value);
  };
  function submitHandler(event) {
    event.preventDefault(); // By default a button placed inside a form will act as a submit button and reload the browser
    // By page will reload because of a request being sent to the sever to submit the form
    // But we don't want that, we want to control the submit with JavaScript

    const bookingData = {
      classType: enteredTitle,
      time: enteredTime, // the + convert the amount to a number instead of a string 
      date: new Date(enteredDate), // Construct a new date , this will pharse the date string into Date() to create a new Date object
      duration: `${enteredDuration} min`,
    };
    //When the Add Booking button is pressed the submit Handler is triggered and the booking data gets build and passed to NewBooking
    props.onSaveBookingData(bookingData);
    console.log(bookingData.amount);
    setEnteredTitle("");
    setEnteredTime("");
    setEnteredDate("");
    setEnteredDuration("")
    createButtonState(false);
  };

  function createState() {
    createButtonState(true);
    cancelButtonState(false);
  };
  function cancelState() {
    cancelButtonState(true);
    createButtonState(false);
  };

  if (create == true && cancel == false) {
    return (
      <form onSubmit={submitHandler}>
        
          <div className={styles.new_booking__controls}>
            <label>Title</label>
            <input
              type="text"
              onChange={titleChangeHandler}
              value={enteredTitle}
            />
          </div>
          <div className={styles.new_booking__controls}>
            <label>Time</label>
            <input
              type="time"
              min="06:00" max="18:00"            
              onChange={timeChangeHandler}
              value={enteredTime}
            />
          </div>
          <div className={styles.new_booking__controls}>
            <label>Date</label>
            <input
              type="date"
              min="2018-01-01"
              max="2033-12-31"
              onChange={dateChangeHandler}
              value={enteredDate}
            />
          </div>
          <div className={styles.new_booking__controls}>
            <label>Duration</label>
            <input
              type="number"
              step={5}
              min="0"
              max="120"
              onChange={durationChangeHandler}
              value={enteredDuration}
            />
          </div>        
        <div className={styles.new_booking__actions}>
          <button type="button" onClick={cancelState}>
            Cancel
          </button>
          <button type="submit">Add Booking</button>
        </div>
      </form>
    );
  };

  return (
    <div>
      <div >
        <button type="button" onClick={createState}>
          Create New Booking
        </button>
      </div>
    </div>
  );
}

export default BookingForm;
