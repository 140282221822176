import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TimeTablePage from "./Components/Pages/TimeTablePage";
import BookingsPage from "./Components/Pages/BookingsPage";
import SignInPage from "./Components/Pages/SignInPage";
import SignUpPage from "./Components/Pages/SignUpPage";
import UserProfilePage from "./Components/Pages/UserProfilePage";
import UserBookingPage from "./Components/Pages/UserBookingPage"


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/timeTable",
    element: <TimeTablePage />,
  },
  {
    path: "/bookings",
    element: <BookingsPage />,
  },
  {
    path: "/LogIn",
    element: <SignInPage />,
  },
  {
    path: "/SignUp",
    element: <SignUpPage />,
  },
  {
    path: "/profilePage",
    element: <UserProfilePage />,
  },
  {
    path: "/myBookingsPage",
    element: <UserBookingPage />,
  },

]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
