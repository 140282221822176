import React, { useState , useRef } from "react";
import Container from "react-bootstrap/Container";
import "../../../node_modules/bootstrap/dist/css/bootstrap.css";
import styles from "../SignUp/SignUp.module.css";
import { useNavigate } from "react-router-dom";

function SignUp(props) {
  const [confirmPassword, ConfirmPasswordSubmit] = useState("");
  const [invalidPass, InvalidPasswordSubmit] = useState("");
  const [FirebaseErrorMsg, ErrorMessageResponse] = useState("");
  
  //User Data
  const [email, EmailSubmit] = useState("");
  const [password, PasswordSubmit] = useState("");
  const [FirstName, FirstNameSubmit] = useState("");
  const [LastName, LastNameSubmit] = useState("");
  const [DisplayName, DisplayNameSubmit] = useState("");
  const [PhoneNumber, PhoneNumberSubmit] = useState("");
  let FirebaseError = "Please enter a valid email address.";
  let home_Link = "/";
  const navigate = useNavigate()

  function GotoLogin(){
    navigate(home_Link)
  }
  //Sign Up requirements
  function ConPasswordSubmitHandler(event) {
    ConfirmPasswordSubmit(event.target.value);
  }
  function EmailSubmitHandler(event) {
    EmailSubmit(event.target.value);
  }
  function PasswordSubmitHandler(event) {
    PasswordSubmit(event.target.value);
  }
  //User Data Handlers
  function FirstNameSubmitHandler(event) {
    FirstNameSubmit(event.target.value);
  }
  function LastNameSubmitHandler(event) {
    LastNameSubmit(event.target.value);
  }
  function DisplayNameSubmitHandler(event) {
    DisplayNameSubmit(event.target.value);
  }
  function PhoneNumberSubmitHandler(event) {
    PhoneNumberSubmit(event.target.value);
  }
  async function SignUpSubmitHandler(event) {
    event.preventDefault(); // Remember!! By default a button placed inside a form will act as a submit button and reload the browser
    // By page will reload because of a request being sent to the sever to submit the form
    // But we don't want that, we want to control the submit with JavaScript
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent submission
    Array.from(forms).forEach((form) => {
      event.preventDefault();
      event.stopPropagation();
      form.classList.add("was-validated");
    });

    let userData = { FirstName, LastName, email, DisplayName, PhoneNumber };
    let SignUpDetails = { email, password };

    if (
      password === confirmPassword &&
      password != "" &&
      confirmPassword != ""
    ) {
      await props
        .onUserSignUp(SignUpDetails)
        .then((response) => {
          FirebaseError = response;
          console.log(FirebaseError);
          ErrorMessageResponse(FirebaseError);

          if (FirebaseError != "") {
            props.onUserDataSubmit(userData);
            EmailSubmit("");
            ErrorMessageResponse("Please enter a valid email address.");
            PasswordSubmit("");
            ConfirmPasswordSubmit("");
            FirstNameSubmit("");
            LastNameSubmit("");
            DisplayNameSubmit("");
            PhoneNumberSubmit("");
            // Add a popup saying sign up successful!
            GotoLogin()
          } else {
            console.log(FirebaseError);
            EmailSubmit("");
            throw new Error(FirebaseError);
          }
        })
        .catch((error) => {
          FirebaseError = error.message;
          ErrorMessageResponse(FirebaseError);
          console.log(FirebaseError);
          EmailSubmit("");
          throw new Error(FirebaseError);
        });

      await props
        .onUserDataSubmit(userData)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Password does not match");
      ConfirmPasswordSubmit("");
      InvalidPasswordSubmit("Password does not match");
    }
    FirebaseError = "";
  }
  
  return (
    <Container className={`${styles.body}`}>
      <div className={` text-center `}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          fill="currentColor"
          className={`d-block mx-auto mb-4 bi bi-person-vcard ${styles.logo}`}
          viewBox="0 0 16 16"
        >
          <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0Zm-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          <path d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Z" />
        </svg>
        <h2>New User Sign Up Form</h2>
        <p className="lead">
          Enter your details and sign up below to become a member with us. Being
          a member will give you access to our booking system for our classes
          and you can also see our time table in real time.
        </p>
      </div>
      <div className={`row g-5 ${styles.fields}`}>
        <div className={`col-xs-6 col-md-6 col-lg-6 `}>
          <h4 className="mb-3">Your Account Details</h4>
          <hr className="my-4" />

          <form
            className={`needs-validation text-center ${styles.contain}`}
            novalidate="novalidate"
            onSubmit={SignUpSubmitHandler}
          >
            <div className="row g-3 ">
              <div className="col-xs-6 col-sm-6 col-lg-6">
                <label for="firstName" className="form-label">
                  First name
                </label>
                <input
                  type="text"
                  className={`form-control ${styles.form_signUp}`}
                  id="firstName"
                  placeholder=""
                  required
                  value={FirstName}
                  onChange={FirstNameSubmitHandler}
                />
                <div className="invalid-feedback">
                  Valid first name is required.
                </div>
              </div>

              <div className="col-xs-6 col-sm-6 col-lg-6">
                <label for="lastName" className="form-label">
                  Last name
                </label>
                <input
                  type="text"
                  className={`form-control ${styles.form_signUp}`}
                  id="lastName"
                  placeholder=""
                  required
                  value={LastName}
                  onChange={LastNameSubmitHandler}
                />
                <div className="invalid-feedback">
                  Valid last name is required.
                </div>
              </div>
            </div>

            <div className="row-12">
              <label for="username" className="form-label">
                Display Name
              </label>
              <div className="needs-validation">
                <input
                  type="text"
                  className={`form-control ${styles.form_signUp} `}
                  id="username"
                  placeholder="Username"
                  required
                  value={DisplayName}
                  onChange={DisplayNameSubmitHandler}
                />
                <div className="invalid-feedback">
                  Your username is required.
                </div>
              </div>
            </div>

            <div className="row-12">
              <label for="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className={`form-control ${styles.form_signUp}`}
                id="email"
                placeholder="you@example.com"
                onChange={EmailSubmitHandler}
                value={email}
                required
              />
              <div className="invalid-feedback">{FirebaseErrorMsg}</div>
            </div>

            <div className="row-12">
              <label for="email" className="form-label">
                Password
              </label>
              <input
                type="password"
                className={`form-control ${styles.form_signUp}`}
                id="password"
                placeholder="******"
                name="password"
                minlength="6"
                onChange={PasswordSubmitHandler}
                value={password}
                required
              />
              <div className="invalid-feedback">
                Please enter a password with at least 6 characters.
              </div>
            </div>

            <div className="row-12">
              <label for="email" className="form-label">
                Confirm Password
              </label>
              <input
                type="password"
                className={`form-control ${styles.form_signUp}`}
                id="Invalidpassword"
                placeholder="******"
                name="password"
                minlength="6"
                required
                value={confirmPassword}
                onChange={ConPasswordSubmitHandler}
              />
              <div className="invalid-feedback">{invalidPass}</div>
            </div>

            <div className="row-12">
              <label for="address" className="form-label">
                Phone Number
              </label>
              <input
                type="tel"
                className={`form-control ${styles.form_signUp}`}
                id="phone"
                name="phone"
                pattern="[0-9]{10}"
                placeholder="0123456789"
                required
                value={PhoneNumber}
                onChange={PhoneNumberSubmitHandler}
              />
              <div className="invalid-feedback">
                Please enter a valid 10 digit phone number.
              </div>
            </div>

            <hr className="my-4" />

            <div className={`row-12 ${styles.Sbutton}`}>
              <button
                className={`btn btn-primary btn-lg ${styles.signButton}`}
                type="submit"
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </Container>
  );
}

export default SignUp;
