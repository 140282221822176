import Navbar from '../../UI/NavBar';
import Slide from '../../UI/Carousel';
import Featurette from '../../UI/Featurette';
import Footer from '../../UI/Footer';
import Icons from '../../UI/Icons';
import styles from "./HomePage.module.css"

function HomePage() {

  return ( 
  <div className={styles.container}>
    <Navbar/>
    <Slide/>
    <Icons/>
    <Featurette/>    
    <Footer/>
  </div>
    
  );
}

export default HomePage;
