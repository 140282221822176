import styles from "./NewBooking.module.css"
import BookingForm from "./BookingForm";

function NewBooking(props){

    function saveBookingDataHandler(enteredBookingData){
        const bookingData = {
            ...enteredBookingData, // Remember that the ... is copying everything in enteredBookingData and add one more element
            id: Math.random().toString(),
            weekDay: enteredBookingData.date.toLocaleString("en-US", { day: "2-digit" }),
            weekDayName: enteredBookingData.date.toLocaleString("en-US", { weekday: "short" }),
        };
        console.log(bookingData);
        //New Booking build up the booking based on the user input and add a id the gets passed below
        props.onAddBooking(bookingData); // The new created booking is now passed to the BookingPage,js throught the onAddBooking props
    };   

    return <div className={styles.new_booking}>
        <BookingForm onSaveBookingData={saveBookingDataHandler} /> 
        </div>
        
};

export default NewBooking;