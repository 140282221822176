import React, { useState, useEffect } from "react";
import MyBookings from "../Bookings/MyBookings/MyBookings";
import Navbar from "../../UI/NavBar";
import Footer from "../../UI/Footer";
import styles from "./UserBookingPage.module.css";
import Container from "react-bootstrap/Container";
import img1 from "../../Images/23.jpeg";
import { getAuth, onAuthStateChanged } from "firebase/auth";

//*********************************Varaible declaration section******************************************
//Array Off booking object(key value pair)
let currentDate = new Date();
currentDate.setDate(currentDate.getDate());
//*******************************************************************************************************

function UserBookingPage() {
  const auth = getAuth();
  const [bookings, setBookings] = useState([]);
  const [LoggedIn, CheckUserLoggedIn] = useState("");
  const [admin, EmmaLoggedIn] = useState(false);

  //Function Creation inside rendering
  async function ReadBookings() {
    // Read bookings from Cloud
    let response = await fetch(
      `https://emmajanefitnessapi.azurewebsites.net/api/Bookings?`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json, charset=utf-8",
        },
      }
    ).then((response) => response.json());
    // The response is returned from the backend as a Array of objects
    //console.log(response);
    
    //Creating a empty Array to append the new Booking data to
    const Read_bookings = [];
    
    //Here we iterate over the full lenght of the response array 
    for (let bookingItem = 1; bookingItem <= response.length; bookingItem++) {
      //Firts we check if the booking is already in the new Array
      if (response[bookingItem] in Read_bookings) {
        console.log("Booking Already exist");
      } 
      //Next we create a new booking object and append it to the new array
      else{
        //Create a new Date Object from the string value in the response date for each booking
        let today = new Date(response[bookingItem].Date);
        let newBooking = {
          date: today,
          weekDay: today.toLocaleString("en-US", { day: "2-digit" }),
          weekDayName: today.toLocaleString("en-US", { weekday: "short" }),
          classType: response[bookingItem].ClassType,
          time: response[bookingItem].Time,
          duration: response[bookingItem].Duration,
          usersBooked: response[bookingItem].UsersBooked,
        };

        //Here we are creating a String array of the keys returned by the userBooked
        // With the Object.keys() function you can extract the keys in a object, this will give us a list of all the users
        let userBooked = Object.keys(response[bookingItem].UsersBooked);                
        
        if (LoggedIn === "Cp58zcfqSbNRAvlrPTrMOyEWFPf2" || LoggedIn === "4MQZWldm7ddWpgBfx9uXQ0c8ncs1") {
          Read_bookings.push(newBooking);
        }

        //Here we use a for each loop to interate over all users and check if the current user is in the booking list         
        userBooked.forEach(user => {
          // let userCount = 0
          // console.log(userCount)

          if(user === auth.currentUser.uid ){
            //let childMindSelected = Object.values(response[bookingItem].UsersBooked)
            // console.log(childMindSelected[userCount])
            //newBooking.childMinding = childMindSelected[userCount].toLocaleString()
            Read_bookings.push(newBooking);            
          }         
          
          //console.log(Read_bookings)
        });       
      }
      setBookings(Read_bookings);
    }
  }
  async function WriteNewBookingToServer(booking) {
    const month = booking.date.toLocaleString("en-US", { month: "long" });
    const day = booking.date.toLocaleString("en-US", { day: "2-digit" });
    const weekday = booking.date.toLocaleString("en-US", { weekday: "short" });

    let bookingJson = JSON.stringify({
      ClassType: booking.classType,
      DateString: `${month} ${day} ${weekday}`,
      Date: booking.date,
      Time: booking.time,
      Duration: booking.duration,
    });
    //Update/PUT method implementation:
    await fetch(
      `https://emmajanefitnessapi.azurewebsites.net/api/Bookings?jsonString=${bookingJson}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json, charset=utf-8",
        },
      }
    );
  }
  async function UpdateBookingOnServer(booking) {
    const auth = getAuth();
    const month = booking.date.toLocaleString("en-US", { month: "long" });
    const day = booking.date.toLocaleString("en-US", { day: "2-digit" });
    const weekday = booking.date.toLocaleString("en-US", { weekday: "short" });

    let bookingJson = JSON.stringify({
      ClassType: booking.classType,
      Date: `${month} ${day} ${weekday}`,
      Time: booking.time,
      Duration: booking.duration,
      UserBooked: auth.currentUser.uid,
    });
    //Update/PUT method implementation:
    await fetch(
      `https://emmajanefitnessapi.azurewebsites.net/api/Bookings?jsonString=${bookingJson}`,
      {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json, charset=utf-8",
        },
      }
    );
  }
  function getUser() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        const adminUser = user.uid;
        CheckUserLoggedIn(adminUser);
        if (LoggedIn === "Cp58zcfqSbNRAvlrPTrMOyEWFPf2" || LoggedIn === "4MQZWldm7ddWpgBfx9uXQ0c8ncs1") {
          EmmaLoggedIn(true);
        }
      }
    });
  }
  function addBookingHandler(booking) {
    //the new booking get passed in from the onAddbooking function in NewBooking
    WriteNewBookingToServer(booking);
    setBookings((prevBookings) => {
      return [booking, ...prevBookings];
    }); // Here we use a special function. In stead of passing in the new array [Booking, ...bookings] as an arugment
    // We pass in a fuction instead so that setbooking automatically recieve the latest state snapshot
    // We get all our previous bookings and then return a new array where we add this new booking to the old list of bookings

    var delayInMilliseconds = 1000; //1 second

    setTimeout(function () {
      window.location.reload(true); // Refresh the browser
    }, delayInMilliseconds);
  }

  //Function call section
  //Use Effect for Reading Current User and Reading Bookings from Server
  useEffect(() => {
    getUser();
    ReadBookings();
  }, [LoggedIn]);

  return (
    <div>
      <Navbar />
      <div className={styles.booking_jumbotron}>
        <Container>
          <div className="row align-items-lg">
            <div className="col-12">
              <div className=" h-100 ">
                <h1 className="p-5">My Bookings</h1>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container className={styles.hero}>
        <div className="row px-4 py-5 my-5 text-right">
          <div className={`col-lg-6 ${styles.hero_text}`}>
            <h1 className="display-4 fw-bold">View your Bookings below</h1>
            <p className="lead mx-0 m-10 ">
              From the list below you can check the classes that you have
              booked, filtered by date. View each day by selecting the date you
              would like and if you need to add child mainding for your session
              please select the option. You can also cancel your booking from
              the list below.
            </p>
          </div>
          <img src={img1} className={`col-lg-6 ${styles.table_img}`} />
        </div>
      </Container>
      <div className={styles.b_example_divider}></div>
      <Container className={styles.bookings_page}>
        <MyBookings booking_array={bookings} />
      </Container>

      <Footer />
    </div>
  );
}

export default UserBookingPage;
