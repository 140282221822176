import React, { useState } from "react";
import Navbar from "../../UI/NavBar";
import Footer from "../../UI/Footer";
import SignUp from "../SignUp/SignUp";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

function SignUpPage() {
  const auth = getAuth();

  async function AuthenticateUser(UserSignUpDetails) {
    await createUserWithEmailAndPassword(
      auth,
      UserSignUpDetails.email,
      UserSignUpDetails.password
    );
  }

  async function UserDataToServer(userData) {
    //let userDataString = `firstName=${userData.FirstName}&lastName=${userData.LastName}&email=${userData.email}&phoneNumber=${userData.PhoneNumber}&displayName=${userData.DisplayName}`;
    const auth = getAuth();
    let userJson = JSON.stringify({
      firstName: userData.FirstName,
      lastName: userData.LastName,
      email: userData.email,
      phoneNumber: userData.PhoneNumber,
      displayName: userData.DisplayName,
      userID: auth.currentUser.uid,
    });
    console.log(userJson);

    //POST method implementation:
    await fetch(
      `https://emmajanefitnessapi.azurewebsites.net/api/Users?jsonString=${userJson}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json, charset=utf-8",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "strict-origin-when-cross-origin", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: userJson, // body data type must match "Content-Type" header
      }
    )
      .then((response) => response.json())
      .then((data) => console.log(data));
  }

  return (
    <div>
      <Navbar />
      <SignUp
        onUserSignUp={AuthenticateUser}
        onUserDataSubmit={UserDataToServer}
      />
      <Footer />
    </div>
  );
}

export default SignUpPage;
