import React, { useState, useEffect } from "react";
import Bookings from "../Bookings/Bookings";
import Navbar from "../../UI/NavBar";
import Footer from "../../UI/Footer";
import styles from "./BookingsPage.module.css";
import Container from "react-bootstrap/Container";
import NewBooking from "../Bookings/NewBooking/NewBooking";
import img1 from "../../Images/15.jpeg";
import img2 from "../../Images/22.jpeg";
import { getAuth, onAuthStateChanged } from "firebase/auth";

//*********************************Varaible declaration section******************************************
//Array Off booking object(key value pair)
let currentDate = new Date();
currentDate.setDate(currentDate.getDate());
//*******************************************************************************************************

function BookingsPage() {
  const auth = getAuth();
  const [bookings, setBookings] = useState([]);
  const [LoggedIn, CheckUserLoggedIn] = useState("");
  const [admin, EmmaLoggedIn] = useState(false);

  //Function Creation inside rendering
  async function ReadBookings() {
    let response = await fetch(
      `https://emmajanefitnessapi.azurewebsites.net/api/Bookings?`,
      {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json, charset=utf-8",
        },
      }
    ).then((response) => response.json());
    const Read_bookings = [];
    for (let bookingItem = 1; bookingItem <= response.length; bookingItem++) {
      if (response[bookingItem] in Read_bookings) {
        console.log("Booking Already exist");
      } else {
        let today = new Date(response[bookingItem].Date);
        let newBooking = {
          date: today,
          weekDay: today.toLocaleString("en-US", { day: "2-digit" }),
          weekDayName: today.toLocaleString("en-US", { weekday: "short" }),
          classType: response[bookingItem].ClassType,
          time: response[bookingItem].Time,
          duration: response[bookingItem].Duration,
        };
        Read_bookings.push(newBooking);
      }
      setBookings(Read_bookings);
    }
  }
  async function WriteNewBookingToServer(booking) {
    const month = booking.date.toLocaleString("en-US", { month: "long" });
    const day = booking.date.toLocaleString("en-US", { day: "2-digit" });
    const weekday = booking.date.toLocaleString("en-US", { weekday: "short" });

    let bookingJson = JSON.stringify({
      ClassType: booking.classType,
      DateString: `${month} ${day} ${weekday}`,
      Date: booking.date,
      Time: booking.time,
      Duration: booking.duration,
    });
    //Update/PUT method implementation:
    await fetch(
      `https://emmajanefitnessapi.azurewebsites.net/api/Bookings?jsonString=${bookingJson}`,
      {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json, charset=utf-8",
        },
      }
    );
  }
  async function UpdateBookingOnServer(booking) {
    const auth = getAuth();
    const month = booking.date.toLocaleString("en-US", { month: "long" });
    const day = booking.date.toLocaleString("en-US", { day: "2-digit" });
    const weekday = booking.date.toLocaleString("en-US", { weekday: "short" });

    let bookingJson = JSON.stringify({
      ClassType: booking.classType,
      Date: `${month} ${day} ${weekday}`,
      Time: booking.time,
      Duration: booking.duration,
      UserBooked: auth.currentUser.uid,
    });
    //Update/PUT method implementation:
    await fetch(
      `https://emmajanefitnessapi.azurewebsites.net/api/Bookings?jsonString=${bookingJson}`,
      {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json, charset=utf-8",
        },
      }
    );
  }
  function getUser() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        const adminUser = user.uid;
        CheckUserLoggedIn(adminUser);
        if (LoggedIn === "Cp58zcfqSbNRAvlrPTrMOyEWFPf2" || LoggedIn === "4MQZWldm7ddWpgBfx9uXQ0c8ncs1") {
          EmmaLoggedIn(true);
        }
      }
    });
  }
  function addBookingHandler(booking) {
    //the new booking get passed in from the onAddbooking function in NewBooking
    WriteNewBookingToServer(booking);
    setBookings((prevBookings) => {
      return [booking, ...prevBookings];
    }); // Here we use a special function. In stead of passing in the new array [Booking, ...bookings] as an arugment
    // We pass in a fuction instead so that setbooking automatically recieve the latest state snapshot
    // We get all our previous bookings and then return a new array where we add this new booking to the old list of bookings
    
    var delayInMilliseconds = 1000; //1 second

    setTimeout(function () {
      window.location.reload(true); // Refresh the browser
    }, delayInMilliseconds);
  }
  //Function call section
  //Use Effect for Reading Current User and Reading Bookings from Server
  useEffect(() => {
    getUser();
    ReadBookings();
  }, [LoggedIn]);

  return (
    <div>
      <Navbar />
      <div className={styles.booking_jumbotron}>
        <Container>
          <div className="row align-items-lg">
            <div className="col-12">
              <div className=" h-100 ">
                <h1 className="p-5">Bookings</h1>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Container className={styles.hero}>
        <div className="row px-4 py-3  text-right">
          <img src={img1} className={`col-lg-6 ${styles.table_img}`} />
          <div className={` col-lg-6 ${styles.hero_text}`}>
            <h1 className="display-4 fw-bold">
              Book From Our Upcoming Classes
            </h1>
            <p className="lead mx-1 m-10 ">
              From the list below you can check availability on our classes,
              filtered by date by selecting the date you would like to book and
              if you require child mainding for your session please select the
              option before booking a class. All your Bookings will be stored under you profile "My Bookings" page.
            </p>
          </div>
        </div>
        <hr className={styles.featurette_divider} />
        <div className="row px-4 py-3  text-left">
          <div className={`col-lg-6 ${styles.hero_text}`}>
            <h1 className="display-4 fw-bold">We offer Child Minding</h1>
            <p className="lead mx-0 m-10 ">
              Bring the little one's with you and we will look after them for
              you while you shape that new body. We also let them train with
              from time to time. Just to keep the fitness going in the family!
            </p>
          </div>
          <img src={img2} className={`col-lg-6 ${styles.table_img}`} />
        </div>
      </Container>
      <div className={styles.b_example_divider}></div>
      <Container className={styles.bookings_page}>
        <Bookings booking_array={bookings} />
        {admin && <NewBooking onAddBooking={addBookingHandler} />}
      </Container>

      <Footer />
    </div>
  );
}
export default BookingsPage;
