import BookingDate from "../BookingDate";
import styles from "./MyBookingItem.module.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ErrorPopup from "../../Error/ErrorPopup";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.css";
import React, { useState, useEffect } from "react";

function BookingItem(props) {
  const month = props.date.toLocaleString("en-US", { month: "long" });
  const day = props.date.toLocaleString("en-US", { day: "2-digit" });
  const weekday = props.date.toLocaleString("en-US", { weekday: "short" });
  const time = props.time;
  let confirm = false;
  const auth = getAuth();

  const [admin, EmmaLoggedIn] = useState(false);
  const [LoggedIn, CheckUserLoggedIn] = useState("");
  const [bookingSelect, BookingSelectionTrigger] = useState(false);
  const [CMSelect, CMTrigger] = useState(false);
  const [selectedBooking, SelectedBooking] = useState([]);

  // Parent Class passing props to --> BookingDate

  // use Hooks can only be called in React components non normal JS
  // Hooks are used to re-evaluate the data when an onClick/onWhatEver Handler has been triggered to update the value and redraw it on the screen

  // With the useState you create a special kind of variable. useState gets passed the initial value as a argument
  // useState returns 2 things for us: 1. Gives us access to this special variable
  // 2. Returns a function that we can call to assign a new value to the variable
  // Summary: useState returns an Array = [value itself, updating function]

  // You can use destucturing to store both returned elements into seperate variables/constants

  //Add Logged in User selected booking to Server
  async function BookingOnServer(booking) {
    const auth = getAuth();
    let userID = false;
    if (CMSelect == true) {
      userID = true;
    }

    let bookingJson = JSON.stringify({
      Date: `${booking[0]} ${booking[1]} ${booking[2]}`,
      Time: booking[3],
      UserBooked: auth.currentUser.uid,
      ChildMinding: userID,
    });
    //Update/PUT method implementation:
    await fetch(
      `https://emmajanefitnessapi.azurewebsites.net/api/Bookings?jsonString=${bookingJson}`,
      {
        method: "PUT", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json, charset=utf-8",
        },
      }
    );
  }
  //Remove Logged in User selected booking to Server
  async function RemoveBookingOnServer(booking) {
    let bookingJson = `${booking[0]} ${booking[1]} ${booking[2]} ${booking[3]}`;
    //Update/PUT method implementation:
    await fetch(
      `https://emmajanefitnessapi.azurewebsites.net/api/Bookings?bookingID=${bookingJson}&user=${auth.currentUser.uid}`,
      {
        method: "DELETE", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        headers: {
          "Content-Type": "application/json, charset=utf-8",
        },
      }
    );
  }
  //Save Booking Data , Time and Date and Handles Selection Trigger of Each booking Item
  function BookingSelection() {
    let bookItem = `${month} ${day} ${weekday} ${time}`.split(" ");
    SelectedBooking(bookItem);
    BookingSelectionTrigger(true);
  }
  function DeleteBooking(value) {
    confirm = value;
    BookingSelectionTrigger(false);
    if (confirm == true) {
      RemoveBookingOnServer(selectedBooking);
      console.log("Trigger Delete server Booking");
      var delayInMilliseconds = 500; //1 second

      setTimeout(function () {
        window.location.reload(true); // Refresh the browser
      }, delayInMilliseconds);
    }
  }
  function ConfirmBooking(value) {
    confirm = value;
    BookingSelectionTrigger(false);
    if (confirm == true) {
      BookingOnServer(selectedBooking);
      console.log("Trigger Update server Booking");
      var delayInMilliseconds = 800; //1 second

      setTimeout(function () {
        window.location.reload(true); // Refresh the browser
      }, delayInMilliseconds);
    }
  }
  function CMConfirm() {
    if (CMSelect == false) {
      CMTrigger(true);
    } else {
      CMTrigger(false);
    }
  }
  function getUser() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        const adminUser = user.uid;
        CheckUserLoggedIn(adminUser);
        if (LoggedIn === "Cp58zcfqSbNRAvlrPTrMOyEWFPf2") {
          EmmaLoggedIn(true);
        }
      }
    });
  }
  //Function call section
  useEffect(() => {
    getUser();
  }, [LoggedIn]);

  if (admin == true) {
    return (
      <div>
        <ErrorPopup
          bookingSelectTrigger={bookingSelect}
          onBookingConfirm={DeleteBooking}
          title={"Confirm Booking"}
          message={"Would you like to Delete the selected booking?"}
        />
  
        <div className={`row row-cols-12 ${styles.booking_item}`}>
          <BookingDate
            className={`col-lg col-sm col-xs col-md ${styles.booking_item__description}`}
            date={props.date}
          />
          <h2
            className={`col-lg col-sm col-xs col-md ${styles.booking_item__description}`}
          >
            {props.classType}
          </h2>
          <h2
            className={`col-lg col-sm col-xs col-md ${styles.booking_item__description}`}
          >
            {props.time}
          </h2>
          <h2
            className={`col-lg col-sm col-xs col-md ${styles.booking_item__description}`}
          >
            {props.duration}
          </h2>         
  
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            className={`col-lg col-sm col-xs col-md bi bi-calendar-check-fill ${styles.booking_item__description} ${styles.booking_icon}`}
            viewBox="0 0 16 16"
            onClick={BookingSelection}
            type="button"
          >
            <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM6.854 8.146 8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 1 1 .708-.708z" />
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ErrorPopup
        bookingSelectTrigger={bookingSelect}
        onBookingConfirm={DeleteBooking}
        title={"Confirm Booking"}
        message={"Would you like to Delete the selected booking?"}
      />

      <div className={`row row-cols-6 ${styles.booking_item}`}>
        <BookingDate
          className={`col-lg col-sm col-xs col-md ${styles.booking_item__description}`}
          date={props.date}
        />
        <h2
          className={`col-lg col-sm col-xs col-md ${styles.booking_item__description}`}
        >
          {props.classType}
        </h2>
        <h2
          className={`col-lg col-sm col-xs col-md ${styles.booking_item__description}`}
        >
          {props.time}
        </h2>
        <h2
          className={`col-lg col-sm col-xs col-md ${styles.booking_item__description}`}
        >
          {props.duration}
        </h2>
        <div
          className={`col-lg col-sm col-xs col-md form-check form-switch  
          ${styles.CMContainer}`}
        >
          <input
            className={`form-check-input ${styles.booking_CMicon}`}
            type="checkbox"
            role="switch"
            id="flexSwitchCheckDefault"
            onChange={CMConfirm}
          />
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45"
          height="45"
          className={`col-lg col-sm col-xs col-md bi bi-calendar-check-fill ${styles.booking_item__description} ${styles.booking_icon}`}
          viewBox="0 0 16 16"
          onClick={BookingSelection}
          type="button"
        >
          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zM6.854 8.146 8 9.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 10l1.147 1.146a.5.5 0 0 1-.708.708L8 10.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 10 6.146 8.854a.5.5 0 1 1 .708-.708z" />
        </svg>
      </div>
    </div>
  );
}

export default BookingItem;
