import styles from "./BookingDate.module.css";

function BookingDate(props) { // Child Class getting props from  --> BookingItem
  const month = props.date.toLocaleString("en-US", { month: "long" });
  const day = props.date.toLocaleString("en-US", { day: "2-digit" });
  const weekday = props.date.toLocaleString("en-US", { weekday: "short" });

  return (
    <div className={styles.booking_date}>
      <div className={styles.booking_date__month}>{month}</div>
      <div className={styles.booking_date__day}>{day}</div>
      <div className={styles.booking_date__year}>{weekday}</div>
    </div>
  );
}

export default BookingDate;
