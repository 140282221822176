import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import HomePage from "./Components/Pages/HomePage";
import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, signInWithEmailAndPassword, browserLocalPersistence } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAesa0kgYf4PGioB0-P-eW4h-GxbyPX24c",
  authDomain: "emma-jane-fitness.firebaseapp.com",
  projectId: "emma-jane-fitness",
  storageBucket: "emma-jane-fitness.appspot.com",
  messagingSenderId: "937317821020",
  appId: "1:937317821020:web:bcfb7c9145dab2e8f81499",
  measurementId: "G-5XD1W7VC5H"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth();
  setPersistence(auth, browserLocalPersistence)
function App() {
  
  return (
    <div>
      <HomePage />
    </div>
  );
}

export default App;
